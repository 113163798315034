Essa página contem 2 relatórios, a uma versão resumida e detalhada.

<template>
	<panelEagle id="eventosVeiculo" :loading="relatorio.loading">
		<div class="col-sm-12 row">
			<div class="col-sm-5 nopadding">
				<titulo titulo="Eventos do Veículo" :icon="mdiCarInfo" />
			</div>
			<div class="col-sm-7 nopadding divDontPrint">
				<basicEIG
					:disabled="$v.$invalid || !horasValidas "
					:loading="relatorio.loadingExportar"
					@gerarRelatorio="gerarRelatorio"
					@exportarRelatorio="exportarRelatorioResumido"
					:disabledDropdown="$v.$invalid || !relatorio.dados.length || !horasValidas"
					:btnLegenda=true
				/>
			</div>
		</div>
		<slideUpDown class="divDontPrint">
			<div class="col-sm-12"><hr /></div>
			<div class="col-12 row p-0 pb-3">
				<div class="col-9 row nopadding">
					<div class="col-4 nopadding">
						<inputRangeWithLimit 
							name="eventosVeiculoData" 
							:isObrigatorio="true"
							opens='rigth'
							:intervalo="7"
							@changeInput="changeData" 
							/>
					</div>
					<div class="col-4 nopadding">
						<select-all
							ref="refSeletorEmpresa"
							nameForRadio="seletorEmpresa"
							extraClassParent="nopadding"
							:labels="labelSeletorEmpresa"
							@changeSelect="changeEmpresa"
							:isMultiple=multiplasEmpresas
							:hasSelectAll=multiplasEmpresas
							:optionsArray="optSelectEmpresa"
							:selected="selectedEmpresa"
						/>
					</div>
					<div class="col-4 nopadding">
						<selectAll
							ref="refSeletorVeiculo"
							nameForRadio="seletorVeiculo"
							:disabled="!optSelectVeiculo.length > 0"
							:labels="labelSeletorVeiculo"
							:isMultiple=multiplasEmpresas
							:hasSelectAll=multiplasEmpresas
							:optionsArray="optSelectVeiculo"
							@changeSelect="changeVeiculo"
							firstSelected="V"
							:loading="relatorio.loadingVeiculos"
						/>
					</div>
					<div class="col-12 row p-0 pt-2">
						<div class="col-4 nopadding">
							<inputSimple
								:inputClass="{ 'border border-danger': !horaIniOk }"
								@changeInput="changeHoraInicio"
								:hasMask="true"
								:mask="['hN:MN']"
								:value="relatorio.horaini"
								label="Hora Inicial"
							/>
						</div>
						<div class="col-4 nopadding">
							<inputSimple
								:inputClass="{ 'border border-danger': !horaFimOk }"
								extraClass="p-0"
								@changeInput="changeHoraFim"
								:hasMask="true"
								:mask="['hN:MN']"
								:value="relatorio.horafim"
								label="Hora Final"
							/>
						</div>
						<div class="col-4 nopadding">
							<selectAll
								ref="refSeletorEventos"
								nameForRadio="seletorEventos"
								id=""
								:disabled="!optSelectVeiculo.length > 0"
								:labels="labelSeletorEventos"
								:isMultiple="true"
								:hasSelectAll="true"
								:optionsArray="optSelectEventos"
								@changeSelect="changeEventos"
								firstSelected="E"
								:loading="relatorio.loadingVeiculos"
								:selected="selectedEventos"
							/>
						</div>
					</div>
				</div>
				<div class="col-3 pt-4 nopadding row confirmaServicos mt-4 ">
					<div>
						<b-form-checkbox                                
							v-model="multiplasEmpresas"
							:id="checkboxMultiplasEmpresas"
							name="multiEmpresacheck"
							@change="changeCheckboxMultiEmpresa()"
						/>
					</div>
					<div style="text-align: left">
						Selecionar múltiplas empresas e veículos
					</div>
				</div>
			</div>
		</slideUpDown>
		<div class="col-sm-12 divDontPrint"><hr /></div>
		<div class="col-sm-12 divDontPrint">
			<tableSimples @fimDaRolagem="pegaProximaPagina">
				<thead>
					<tr>
						<th>Data processamento</th>
						<th>Data evento</th>
						<th>Evento</th>
						<th>Ponto</th>
						<th>Região</th>
						<th>Endereço</th>
						<th>Latitude, Longitude</th>
						<th>Hodômetro</th>
						<th>Ignição</th>
						<th>Km/h</th>
						<th>Bateria</th>
						<th>Motorista</th>
						<th>Localizar</th>
					</tr>
				</thead>
				<tbody v-if="mostrarDados" >
					<template v-for="(veiculo, index) in relatorio.dados" ref="tableSize">
						<tr :key="`${index}__placa`" class="linhaPlaca">
							<td colspan="12" class="p-0 pt-3">
								<span class="placaExcesso">
									{{ veiculo.index }}
								</span>
							</td>
						</tr>
						<tr
							v-for="(reg, i) in veiculo.registros"
							:key="`${i}_${index}_${index}`"
							class="trStyle"
						>
							<td>{{ reg.dataprocessado }}</td>
							<td style="width: 165px; text-align: center;">{{ formataDataHora(reg.dataevento) }}</td>
							<td :style="validaAlerta(reg)">
								<baseIcon
									style="margin-right: 5px"
									size="20"
									class=""
									:icon="decideIconeMotivoTransmissao(reg.origem)"
								/>
								{{ reg.motivotransmissao }}
							</td>
							<td>{{ reg.ponto }}</td>
							<td>{{ reg.regiao }}</td>
							<td>{{ reg.endereco }}</td>
							<td>{{ reg.latlng }}</td>
							<td>{{ reg.hodometro }}</td>
							<td>
								<baseIcon
									v-if="reg.origem == 'bilhetes'"
									v-bind="defineIgnicao(reg.ignicao)"
								/>
							</td>
							<td>{{ reg.kmh }}</td>
							<td style="width: 4.5%">
								<span v-if="reg.bateria === null">-</span>
								<span v-else-if="reg.bateria >= 0 && reg.origem == 'bilhetes'">
									{{ reg.bateria }}%
									<baseIcon
										style="color: green"
										v-bind="defineIconeBateria(reg.bateria)"
									/>
								</span>
							</td>
							<td>
								{{reg.condutor}}
							</td>
							<td>
								<span
									v-if="reg.latlng"
									class="botao"
									event="click"
									@click="linkGoogleMaps(reg.latlng)"
								>
									<baseIcon size="18" class="cursor-pointer corIcone" :icon="mdiTarget" />
								</span>
							</td>
						</tr>
					</template>
				</tbody>
				<tbody v-else>
					<statusBar :statusBar="relatorio.info" 
					:colspanForTd="13"/>
				</tbody>
			</tableSimples>
		</div>




		<div>
			<b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
				<div class="col-sm-12 nopadding row">
					<div class="col-sm-12 nopadding">
						<subtitle :items="itemsSubtitlePontos">
						</subtitle>
					</div>
				</div>
			</b-popover>
		</div>





	</panelEagle>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { DateTime } from "luxon";
import {
	mdiCarInfo,
	mdiTarget,
	mdiCellphoneMessage,
	mdiDrawPen,
	mdiCarConnected,
	mdiCheckCircle,
	mdiCloseCircle,
	mdiBattery30,
	mdiBattery60,
	mdiBattery90,
	mdiBattery,
} from "@mdi/js";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { mapGetters } from "vuex";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { validarHoras } from "@/Services/Helpers/DataHelper.ts";
  

export default {
	name: "excessoVelocidade",
	components: {
		baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
		inputRangeWithLimit: require('@/components/Atom/Datas/InputRangeWithLimit').default,
		statusBar: require("@/components/Atom/StatusInformation/StatusInformation").default,
		tableSimples: require("@/components/Atom/Table/TableSimples").default,
		SelectAll: require("@/components/Atom/Select/SelectAll").default,
		panelEagle: require("@/components/Atom/Panel/PanelEagle").default,
		titulo: require("@/components/Atom/Header/Titulo").default,
		slideUpDown: require("@/components/Atom/SlideUpAndDown/SlideUpAndDown").default,
		basicEIG: require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG").default,
		inputSimple: require("@/components/Atom/Inputs/InputSimple").default,
		subtitle: require('@/components/Atom/Subtitle/Subtitle').default,

	},

	data() {
		return {
			url: "/telemetria/relatorios/eventos/veiculo/",
			mdiTarget: mdiTarget,
			mdiCarInfo: mdiCarInfo,
			mdiBattery: mdiBattery,
			mdiBattery90: mdiBattery90,
			mdiBattery60: mdiBattery60,
			mdiBattery30: mdiBattery30,
			mdiCellphoneMessage: mdiCellphoneMessage,
			mdiDrawPen: mdiDrawPen,
			mdiCarConnected: mdiCarConnected,
			mdiCheckCircle: mdiCheckCircle,
			mdiCloseCircle: mdiCloseCircle,
			labelSeletorVeiculo: [{ indexDFH: "V", description: "Veículo*" }],
			labelSeletorEmpresa: [{ indexDFH: "C", description: "Empresa*" }],
			labelSeletorEventos: [{ indexDFH: "E", description: "Eventos" }],
			optSelectEmpresa: [],
			optSelectVeiculo: [],
			optSelectEventos: [
				{value: 80, description:'Aceleração brusca'},
				{value: 89, description:'Aceleração brusca em curva'},
				{value: 2000, description:'Banguela'},
				{value: 90, description:'Colisão'},
				{value: 4000, description:'Conduzir sem se identificar'},
				{value: 87, description:'Curva em alta velocidade'},
				{value: 78, description:'Excesso Velocidade Chuva'},
				{value: 37, description:'Excesso Velocidade Seco'},
				{value: 82, description:'Freada brusca'},
				{value: 88, description:'Freada brusca em curva'},
				{value: 3000, description:'Fora de faixa verde'},
				{value: 69, description:'Marcha Lenta Excessiva (Excesso de tempo parado com ignição ligada)'},
				{value: 1000, description:'Rotação Excessiva'},
			],
			itemsSubtitlePontos: [
				{ 'description': 'Eventos do veículo', 'spanColor': '', 'icone': mdiCarConnected, 'iconColor': 'iconGreen' },
				{ 'description': 'Comandos enviados', 'spanColor': '', 'icone': mdiCellphoneMessage, 'iconColor': 'iconBlue' },
				{ 'description': 'Tratativas realizadas', 'spanColor': '', 'icone': mdiDrawPen, 'iconColor': 'iconOrange' },
			],

			next_page: "",
			loading: false,
			relatorio: {
				data: null,
				cliente: null,
				veiculo: null,
				horaini: "00:00",
				horafim: "23:59",
				eventos:[],
				dados: [],
				info: "info",
				loading: false,
				loadingExportar: [false, false, false],
				loadingVeiculos: false,
			},
			selectedEmpresa: [],
			horaIniOk: true,
			horaFimOk: true,
			alertas: [
				11,
				13,
				15,
				17,
				19,
				23,
				25,
				27,
				31,
				33,
				35,
				37,
				45,
				46,
				47,
				48,
				53,
				67,
				68,
				69,
				70,
				80,
				82,
				87,
				88,
				89
			],
			paramsCopia:{},
			multiplasEmpresas: false,
			checkboxMultiplasEmpresas: '',
			selectedEventos: [],	
		};
	},

	validations: {
		relatorio: {
			data: { required },
			cliente: { required },
			veiculo: { required },
			eventos: {
				required: requiredIf(function () {
					if (!this.multiplasEmpresas) {
						return false
					}
					return true
				}),
			},
		},
		// Validação caso Seleconar multiplas empresas estiver marcado.
	},

	methods: {
		/** 
		 * formata a hora de  yyyy-LL-dd HH:mm:ss para dd/LL/yyyy HH:mm:ss
		 * @author Otávio 🦆 
		*/
		formataDataHora(dataHora){
			if (dataHora) {
				var data = DateTime.fromFormat(dataHora, "yyyy-LL-dd HH:mm:ss").toFormat("dd/LL/yyyy HH:mm:ss");
				return data;
			}
			return null;
		},
		/**
		 * Essa função pega a próxima pagina 
		 * e faz a requisição da mesma.
		 * Depois eu faço umas gambiarrinhas para
		 * poder juntar os valores hehe...
		 * Sei que dava para melhorar essas função 
		 * futuramente ._.
		 * @author Marcos
		*/
        pegaProximaPagina() {
            if (!this.loading && this.next_page && this.relatorio.dados[0] != undefined) {
				this.relatorio.loading = true
				this.loading = true;
				let obj = this.paramsCopia;
                let regex = /\d+.?$/
                let number = regex.exec(this.next_page)
                let urlProximaPagina = this.url+'gerar?page='+number[0]
                new HttpRequest().Post(urlProximaPagina, obj)
				.then((response) => {
						this.next_page = response.data.next_page_url ?? false
						const dados = Object.values(response.data.data)
						const dadosSemUltimo = dados.slice(0, -1)
						this.relatorio.dados[0].registros = 
							this.relatorio.dados[0].registros.concat(dadosSemUltimo)
					})
					.catch(err => {
						conectionError()
					})
					.finally(() => {
						this.relatorio.loading = false;
						this.loading = false;
					});
            }
        },
		...mapGetters(["getMaster"]),

		changeData(value) {
			this.limparRelatorio();
			this.relatorio.data = value;
		},

		changeEmpresa(value) {
			this.limparVeiculo();
			// Verifica se multiplasEmpresas está ativado
			if (this.multiplasEmpresas) {
				this.relatorio.cliente = value;
			} else {
				this.relatorio.cliente = [value[0]];
			}
			this.buscarFiltros();
		},

		changeVeiculo(value) {
			this.limparRelatorio();

			if (this.multiplasEmpresas) {
				this.relatorio.veiculo = value;
			} else {
				this.relatorio.veiculo = [value[0]];
			}
			console.log(this.relatorio.veiculo);
		},

		changeEventos(value){
			this.limparRelatorio();
			this.relatorio.eventos = value;
		},

		changeHoraInicio(value) {
			this.limparRelatorio();
			this.relatorio.horaini = value;
			let valido = validarHoras(this.relatorio.horaini, this.relatorio.horafim);
			this.horaIniOk = valido.inicioOk;
			this.horaFimOk = valido.fimOk;
		},

		/**
		* @description Função para alterar o comportamento 
		*  dos Selects Empresa/Veiculos/Eventos. 
		*  Veiculos e Eventos tornan-se multiplos e enventos nao.
		* @param {Boolean} multiplasEmpresas - Variavel de controle do checkbox
		* @author VeCo® 🔱
		*/
		changeCheckboxMultiEmpresa(){
			if(!this.multiplasEmpresas){
				this.multiplasEmpresas = false;
				this.labelSeletorEventos = [{ indexDFH: "E", description: "Eventos" }];
			}else{
				const msg = "Ao selecionar múltiplas empresas o relatório irá gerar somente os eventos selecionados."
				this.toastShow("Eventos de Veículo", msg, "warning");
				this.multiplasEmpresas = true;
				this.labelSeletorEventos = [{ indexDFH: "E", description: "Eventos*" }];
			}
			this.selectedEmpresa = [];
			this.selectedEventos = [];
		},

		/**
		* @description toastShow 
		*  Exibe a msg no canto direito superior como um toastShow. 
		*/
		toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            });
        },

		changeHoraFim(value) {
			this.limparRelatorio();
			this.relatorio.horafim = value;
			let valido = validarHoras(this.relatorio.horaini, this.relatorio.horafim);
			this.horaIniOk = valido.inicioOk;
			this.horaFimOk = valido.fimOk;
		},

		limparVeiculo() {
			this.relatorio.veiculo = null;
			this.$refs.refSeletorVeiculo.clearAll();
			this.optSelectVeiculo = [];
			this.limparRelatorio();
		},

		limparRelatorio() {
			this.relatorio.dados = [];
			this.relatorio.info = "info";
		},

		async buscarFiltros() {
			if (this.relatorio.cliente) {
				this.relatorio.loadingVeiculos = true;
				const empresasData = this.multiplasEmpresas
					? this.relatorio.cliente // Se multiplasEmpresas for true, envia como está
					: [this.relatorio.cliente]; // Se for false, coloca dentro de um array

				await new FiltrosService()
					.dados_filtros(empresasData, ["V"])
					.then((data) => {
						if (data) {
							this.optSelectVeiculo = data.V ?? [];
						}
					})
					.catch(() => conectionError())
					.finally(() => (this.relatorio.loadingVeiculos = false));
			}
		},

		montaPeriodo() {
			let horaIni = this.relatorio.horaini == 5 ? +"00" : this.relatorio.horaini;
			let horaFim = this.relatorio.horafim == 5 ? +"59" : this.relatorio.horafim;
			let data = this.relatorio.data.split(" - ");
			horaIni = data[0] + " " + horaIni;
			horaFim = data[1] + " " + horaFim;
			return [horaIni, horaFim];
		},

		async exportarRelatorioResumido(tipo) {
			this.setLoadingExportar(tipo);
			const periodo = this.montaPeriodo();
			let url = `${this.url}exportar`;
			let obj = {
				tipo: tipo,
				data: this.relatorio.data,
				cliente: this.relatorio.cliente,
				veiculo: this.relatorio.veiculo,
				periodo: periodo,
				arrayDados: this.relatorio.dados,
				eventos: this.relatorio.eventos,
			};
			await new HttpRequest()
				.Post(url, obj)
				.then(({ data, status }) => {
					if (status && data) {
						var root = process.env.VUE_APP_ROOT;
						window.open(root + "/" + data.dados);
					}
				})
				.catch(() => conectionError())
				.finally(() => this.setLoadingExportar());
		},

		setLoadingExportar(tipo = false) {
			let load = [tipo == "pdf", tipo == "xls", tipo == "csv"];
			this.relatorio.loadingExportar = load;
		},

		async gerarRelatorio() {
			this.relatorio.loading = true;
			this.relatorio.dados = [];
			let url = `${this.url}gerar`;
			let params = {
				data: this.relatorio.data,
				cliente: this.relatorio.cliente,
				veiculo: this.relatorio.veiculo,
				horaini: this.relatorio.horaini,
				horafim: this.relatorio.horafim,
				eventos: this.relatorio.eventos,
				multiplas: this.multiplasEmpresas,
			};
			this.paramsCopia = params
			console.log(params);
			await new HttpRequest()
				.Post(url, params)
				.then(({ data, status }) => {
					if (status && data) {
						this.relatorio.dados = data ? Object.values(data.data["dadosTratados"]) : [];
						this.next_page = data.next_page_url;
						if (!this.relatorio.dados.length) {
							this.relatorio.info = "error";
						}
					} else {
						conectionError();
						this.relatorio.info = "error2";
					}
				})
                .catch(() => conectionError())
				.finally(() => (this.relatorio.loading = false));
		},

		/**
		 * Apenas valida se o motivo de transmissão
		 * esta dentro do array de alertas ou se o registro é uma tratativa.
		 * Se estiver, retorna a cor da fonte em vermelho.
		 */
		validaAlerta(registro) {
			if (this.alertas.includes(registro.evento)) return "color:#D94D45";
			return "";
		},

		defineIconeBateria(porcentagemBateria) {
			if (porcentagemBateria > 90) {
				return {
					icon: mdiBattery,
					size: 14,
					style: "color: #5BC936",
				};
			} else if (porcentagemBateria <= 90 && porcentagemBateria > 60) {
				return {
					icon: mdiBattery90,
					size: 14,
					style: "color: #5BC936",
				};
			} else if (porcentagemBateria <= 60 && porcentagemBateria > 30) {
				return {
					icon: mdiBattery60,
					size: 14,
					style: "color: #DECC47",
				};
			} else if (porcentagemBateria <= 30) {
				return {
					icon: mdiBattery30,
					size: 14,
					style: "color: #D94D45",
				};
			}
		},

		linkGoogleMaps(value) {
			let latlng = value?.split(",") ?? [0, 0];
			window.open(`https://www.google.com/maps/place/${latlng[0]},${latlng[1]}`);
		},

		decideIconeMotivoTransmissao(origem) {
			switch (origem) {
				case "bilhetes":
					return this.mdiCarConnected;
				case "comandos":
					return this.mdiCellphoneMessage;
				case "tratativas_alerta":
					return this.mdiDrawPen;
				default:
					break;
			}
		},

		defineIgnicao(ignicao) {
			if (ignicao) {
				return {
					size: 18,
					icon: mdiCheckCircle,
					style: "color: #5BC936",
				};
			} else {
				return {
					size: 18,
					icon: mdiCloseCircle,
					style: "color: #D94D45",
				};
			}
		},
	},

	computed: {
		dadosRelatorio() {
			return this.relatorio.dados
		},

		mostrarDados() {
			return this.relatorio.dados && this.relatorio.dados.length;
		},

		horasValidas() {
			return this.horaIniOk && this.horaFimOk;
		},
	},

	mounted() {
		this.optSelectEmpresa = new EmpresasService().Get();
		if (!this.getMaster()) {
			this.selectedEmpresa = [this.optSelectEmpresa[0]] ?? [];
		}
	},
};
</script>

<style lang="scss">
#eventosVeiculo {
	tbody {
		.linhaPlaca {
			margin-top: 10px;
			background-color: #fff !important;
		}
	}
	.trStyle {
		font-size: 12px;
		background: #eee !important;
		td {
			padding-left: 5px;
			background: #eee !important;
		}
	}
	.botao::before {
		font-size: 16px;
		cursor: pointer;
	}
	.table {
		tbody {
			tr.linhaPlaca {
				background: #fff !important;
			}
		}
		border-spacing: 40px;
	}
	.placaExcesso {
		width: 300px;
		background-color: #777;
		padding-left: 0px;
		padding-right: auto;
		color: white;
		padding-top: 4x !important;
		font-size: 15px !important;
		text-align: center !important;
		margin-top: 15px;
		height: 30px;
		vertical-align: middle;
		display: table-cell;
	}
	td {
		text-align: left;
	}
	th {
		text-align: left;
	}
	.corIcone {
		color: #2196f3;
	}
	.confirmaServicos{
            font-family: nexabook, sans-serif !important;
            padding: 2px !important;
			justify-content: center;
        }

}
</style>
